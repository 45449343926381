<template>
  <div id="campaigns">
    <campaigns-list />
  </div>
</template>

<script>
import CampaignsList from "./campaigns-list/CampaignsList.vue";

export default {
  name: "Campaigns",
  components: {
    CampaignsList,
  },
};
</script>
