<template>
  <b-sidebar
    id="add-new-campaign-sidebar"
    :visible="isAddNewCampaignSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-campaign-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          Add Campaign
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Campaign Name -->
          <validation-provider
            #default="validationContext"
            name="Campaign Name"
            rules="required"
          >
            <b-form-group
              label="Campaign Name"
              label-for="campaign-name"
            >
              <b-form-input
                id="campaign-name"
                v-model="campaignData.campaignName"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Mother Day"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Estimated Budget -->
          <validation-provider
            #default="validationContext"
            name="Estimated Budget"
            rules="required"
          >
            <b-form-group
              label="Estimated Budget"
              label-for="estimated-budget"
            >
              <b-form-input
                id="estimated-budget"
                v-model="campaignData.estimatedBudget"
                :state="getValidationState(validationContext)"
                trim
                placeholder="50000"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Actual Budget -->
          <validation-provider
            #default="validationContext"
            name="Actual Budget"
            rules="required"
          >
            <b-form-group
              label="Actual Budget"
              label-for="actual-budget"
            >
              <b-form-input
                id="actual-budget"
                v-model="campaignData.actualBudget"
                :state="getValidationState(validationContext)"
                trim
                placeholder="50000"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Active -->
          <validation-provider
            #default="validationContext"
            name="Active"
          >
            <b-form-group>
              <div class="d-flex">
                <b-form-checkbox
                  id="active"
                  v-model="campaignData.active"
                  :state="getValidationState(validationContext)"
                  trim
                />
                <label>Active</label>
              </div>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- From to -->
          <validation-provider
            #default="validationContext"
            name="From - to"
            rules="required"
          >
            <b-form-group
              label="Start - End dates"
              label-for="from-to"
            >
              <flat-pickr
                id="from-to"
                v-model="campaignData.rangeDate"
                class="form-control"
                :config="{ mode: 'range' }"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Date"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Return -->
          <validation-provider
            #default="validationContext"
            name="Return"
            rules="required"
          >
            <b-form-group
              label="Return"
              label-for="return"
            >
              <b-form-input
                id="return"
                v-model="campaignData.return"
                :state="getValidationState(validationContext)"
                trim
                placeholder="50000"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Order Goals -->
          <validation-provider
            #default="validationContext"
            name="Order Goals"
            rules="required"
          >
            <b-form-group
              label="Order Goals"
              label-for="order-goals"
            >
              <b-form-input
                id="order-goals"
                v-model="campaignData.orderGoals"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Order Goals"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewCampaignSidebarActive",
    event: "update:is-add-new-campaign-sidebar-active",
  },
  props: {
    isAddNewCampaignSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      rangeDate: null,
    };
  },
  setup(props, { emit }) {
    const blankCampaignData = {
      campaignName: "",
    };

    const campaignData = ref(JSON.parse(JSON.stringify(blankCampaignData)));
    const resetcampaignData = () => {
      campaignData.value = JSON.parse(JSON.stringify(blankCampaignData));
    };

    const onSubmit = () => {
      store
        .dispatch("app-campaign/addCampaign", campaignData.value)
        .then(() => {
          emit("refetch-data");
          emit("update:is-add-new-campaign-sidebar-active", false);
        });
    };

    // eslint-disable-next-line operator-linebreak
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetcampaignData);

    return {
      campaignData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#add-new-campaign-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
